import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { tableColumnStyle } from '../../pdf_cst';

const styles = StyleSheet.create({ ...tableColumnStyle,
    picture : {
        width: 30,
    },

})

const PhoneRequestTableRow = ({ items }) => {
    const rows = items.map((item, index) => 
        <View style={item.IsProblem ? styles.rowHigh : styles.row} key={index} wrap={false}>
            <Text style={styles.item15}>{item.Operator}</Text>
            <Text style={styles.item5}>{item.Lot}</Text>
            <Text style={styles.item30}>{item.Title}</Text>
            <Text style={styles.item20}>{item.Bidder}</Text>
            <Text style={styles.itemPhone}>{item.Phone}</Text>
            <Text style={styles.itemPhone}>{item.Phone2}</Text>
        </View>
    ) 

    return (<Fragment>{ rows }</Fragment>)
};

export default PhoneRequestTableRow;